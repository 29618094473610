<template>
  <div class="col-md-6 col-lg-3 ftco-animate bg-white mb-5">
    <div class="staff">
      <img v-if="agent.image" class="img" :src="agent.image ? agent.image : '/images/avatar.png'"
          :alt="`${agent.fullname}-img`" />
      <div class="text pt-4">
        <h3 class="mb-2">{{ agent.fullname }}</h3>
        <a
          v-if="agent.cellphone"
          class="position mb-1 text-dark"
          :href="'tel:' + agent.cellphone"
          target="_blank"
          ><i class="fas fa-phone"></i> : {{ agent.cellphone }}</a
        >
        <a
          v-if="agent.email"
          class="position mb-1 text-dark"
          :href="'mailto:' + agent.email"
          target="_blank"
          ><i class="fas fa-envelope"></i> : {{ agent.email }}</a
        >
        <p v-if="agent.biografia">
          {{ agent.biografia }}
        </p>
        <ul class="ftco-social d-flex">
          <li v-if="agent.tweet" class="ftco-animate">
            <a :href="agent.tweet" target="_blank"
              ><span class="icon-twitter"></span
            ></a>
          </li>
          <li v-if="agent.fb" class="ftco-animate">
            <a :href="agent.fb" target="_blank"
              ><span class="icon-facebook"></span
            ></a>
          </li>
          <li v-if="agent.gmas" class="ftco-animate">
            <a :href="agent.gmas" target="_blank"
              ><span class="icon-google-plus"></span
            ></a>
          </li>
          <li v-if="agent.instagram" class="ftco-animate">
            <a :href="agent.instagram" target="_blank"
              ><span class="icon-instagram"></span
            ></a>
          </li>
          <li v-if="agent.skypeid" class="ftco-animate">
            <a :href="agent.skypeid" target="_blank"
              ><span class="icon-skype"></span
            ></a>
          </li>
          <li v-if="agent.linkedin" class="ftco-animate">
            <a :href="agent.linkedin" target="_blank"
              ><span class="icon-linkedin"></span
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);

      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
.staff img{
    object-fit: contain;
    object-position: center;
    width: 100%;
}
p,a{
    overflow-wrap: break-word;
}
a{
    cursor: pointer;
}
</style>