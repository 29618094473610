<template>
  <main>


    <!--/ Property Grid Star /-->
    <section class="ftco-section bg-light">
      <div class="container">
        <div class="row">
          <div
            class="col-md-4"
            v-for="(item, i) in properties"
            :key="item.folio"
            :name="item.folio"
            :index="i"
          >
            <PropertyCard :item="item" />
          </div>
        </div>
      </div>
    </section>
    <!--/ Property Grid End /-->
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue"
import PropertyCard from "@/components/properties/PropertyCard.vue";
import SearchForm from "@/components/search/SearchForm.vue";

export default {
  components: {
    Banner,
    PropertyCard,
    SearchForm
  },
  data() {
    return {
      codeShareProperties: 0,
    };
  },
  computed: {
    ...mapGetters({
      properties: "_getProperties",
    }),
  },
  created() {
    if (this.$route.params.codeShareProperties) {
      this.codeShareProperties = this.$route.params.codeShareProperties;
    }
    this.$store.dispatch("getProperties", this.codeShareProperties);
  },
};
</script>
