<template>
  <div
    class="hero-wrap"
    style="background-image: url('./images/bg_1.jpg');">
    <div class="overlay"></div>
    <div class="container">
      <div
        class="row no-gutters slider-text align-items-center justify-content-center"
      >
        <div class="col-md-9 ftco-animate text-center">
          <p  class="breadcrumbs">
            <span class="mr-2"><a href="/"> {{ $t("routes.home.breadcrumb") }}</a></span
            >/ <span> {{$t(`${pageTitle}`)}}</span>
          </p>
          <h1 class="mb-3 bread">{{$t(`${pageTitle}`)}}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image:{
      type: String,
      require: true
    },
    pageTitle:{ type:String, required:true}
  },
};
</script>

<style></style>
