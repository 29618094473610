<template>
  <main>
    <!-- Content ================================================== -->
    <section class="ftco-section ftc-no-pb mb-3">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-12 wrap-about ftco-animate fadeInUp ftco-animated">
            <div class="heading-section heading-section-wo-line mb-3">
              <div class="text-center ">
                <h2 class="mb-4">{{$t("pages.about.company.title")}}</h2>
              </div>
            </div>
            <div class="row mb-3 p-4">
              <p>
                {{$t("pages.about.company.description")}}
              </p>
            </div>

            <div class="row mb-3 p-4">
              <div class="row mb-5 mt-3 text-center">
                <div class="col-md-4 ">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.vision.title")}}</p>
                  </h2>
                  <br />
                  <p
                    class="text-darker"
                    data-animation-direction="from-left"
                    data-animation-delay="650"
                  >
                    {{$t("pages.about.vision.description")}}
                  </p>
                </div>
                <div class="col-md-4">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.mision.title")}}</p>
                  </h2>
                  <br />
                  <p
                    data-animation-direction="from-left"
                    data-animation-delay="650"
                  >
                    {{$t("pages.about.mision.description")}}
                  </p>
                </div>
                <div class="col-md-4">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.values.title")}}</p>
                  </h2>
                  <br />
                  <p
                    data-animation-direction="from-left"
                    data-animation-delay="650"
                  >
                    <ul >
                  <li>
                    {{$t("pages.about.values.example_one.description")}}

                  </li>
                  <li>{{$t("pages.about.values.example_two.description")}}</li>
                </ul>
                  </p>
                </div>
              </div>
              <router-link to="/contacto" class="btn btn-primary py-3 px-5"
                >{{$t("pages.about.contact-button")}}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Banner from "@/components/layout/Banner.vue";
export default {
  components: {
    Banner,
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
img {
  width: inherit;
}
.btn-primary {
  margin: auto;
}
</style>
