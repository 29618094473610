<template>
    <main class="clean">
        <HomeCarousel :items="items"/>

        <SearchForm/>

        <div>
      <div class="ftco-section ftco-properties">
        <div class="container">
            <div class="row justify-content-center mb-5 pb-3">
                <div class="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
                    <h2 class="mb-4"> 
                        {{ $t("pages.home.title_properties") }} 
                    </h2>     
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <Preloader v-if="!properties"/>
                    <div class="row justify-content-center" v-else>
                        <PropertyCard class="col-md-4 col-xs-6"
                        v-for="(item, i) in properties"
                        :key="item.folio"
                        :name="item.folio"
                        :index="i" :item="item" />
                    </div>
                </div>
            </div>
        </div>
      </div>     
    </div> 
        
    </main>
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import Preloader from "@/components/layout/Preloader.vue";
export default {
  components: {
    HomeCarousel,
    SearchForm,
    PropertyCard,
    Preloader
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
};
</script>

<style>
	.listing-item {
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
		background-color: #fff;
		display: block;
		width: 100%;
		height:auto;
		border-radius: 3px;
		margin-bottom: 30px;
	}
	#propImg {
		width: 100%;
		height: 100%;
  	}

  .clean{
    overflow: hidden;
  }


</style>