<template>
  <div>

    <section class="ftco-section contact-section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8 mb-5">
            <div class="text-center bg-white">
              <img
                v-if="info.logoClean"
                class="logo mt-3 mb-0"
                :src="info.logoClean"
                alt="logo_web"
              />
            </div>
            <ContactForm />
          </div>

          <div class="col-lg-4">
            <div class="pl-4 pb-4 pr-4 mb-3">
              <div class="bg-white p-3 mb-3">
                <p class="mb-0 font-weight-bold text-center color-grey">
                  {{ $t("pages.contact.company_address") }}
                </p>
                <p class="mb-4">
                  {{ info.address }}
                </p>
              </div>

              <div class="bg-white p-3 mb-3">
                <p class="mb-0 font-weight-bold text-center color-grey">
                  {{ $t("pages.contact.company_email") }}
                </p>
                <p class="mb-4">
                  <a :href="'mailto:' + info.reserveemail">{{
                    info.reserveemail
                  }}</a>
                </p>
              </div>

              <div class="bg-white p-3 mb-3">
                <p class="mb-0 font-weight-bold text-center color-grey">
                  {{ $t("pages.contact.company_phone") }}
                </p>
                <p class="mb-4">
                  <a href="#">
                    {{ info.phone }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "@/components/layout/ContactForm.vue";
import Banner from "@/components/layout/Banner.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Banner,
    SearchForm,
    ContactForm,
  },
  data() {
    return {
      contact: true,
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.logo {
  display: inline-block;
  max-width: 10%;
  margin-top: auto;
}

.logo img {
  margin-top: 40%;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.color-grey{
  color: #808080;
}
</style>